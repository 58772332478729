<template>
  <div class="userInfoEn">
    <div class="explain">{{ $t("userInfoTip") }}</div>
    <el-form
      label-width="160px"
      :model="userInfoForm"
      ref="userInfoForm"
      :rules="userInfoRule"
      :hide-required-asterisk="true"
    >
      <div>
        <div class="item clearfix">
          <span class="key">Avatar：</span>
          <span class="value">
            <p v-if="isExamine">
              <span>{{ USER_INFO.name_en | textFormat }}</span>
            </p>
            <div class="name_input" v-else>
              <el-form-item prop="name_en">
                <div class="imgDiv">
                  <div class="markImg tc">
                    <fileUpload
                      @uploadCompleteOSS="uploadimg"
                      :needCropImg="fileUploadOptions.needCropImg"
                      :aspectRatio="fileUploadOptions.aspectRatio"
                      :accept="fileUploadOptions.accept"
                    >
                      <span>Change Avatar</span>
                    </fileUpload>
                  </div>
                  <el-image
                    class="avatar"
                    :src="
                      userInfoForm.avatar
                        ? userInfoForm.avatar
                        : DEFAULT_AVATARSQUARE
                    "
                  ></el-image>
                </div>
              </el-form-item>
            </div>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Name:</span>
          <span class="value">
            <p v-if="isExamine">
              <span>{{ USER_INFO.name_en | textFormat }}</span>
            </p>
            <div class="name_input" v-else>
              <el-form-item prop="name_en">
                <el-input
                  v-model="userInfoForm.name_en"
                  size="small"
                  class="input"
                ></el-input>
              </el-form-item>
            </div>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Email:</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.email | textFormat }}</p>
            <el-form-item :title="$t('notChangeEmailTip')" v-else>
              <el-input
                v-model="userInfoForm.email"
                size="small"
                class="input"
                disabled
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Mobile Phone:</span>
          <span class="value">
            <p v-if="isExamine">
              {{ USER_INFO.mobile | phoneFormat(USER_INFO.area_code) }}
            </p>
            <el-form-item :title="$t('notChangePhoneTip')" v-else>
              <el-input
                v-if="PJSource != 17"
                :value="USER_INFO.mobile | phoneFormat(USER_INFO.area_code)"
                size="small"
                class="input"
                disabled
              ></el-input>
              <el-input
                v-else
                v-model="userInfoForm.mobile"
                size="small"
                class="input mobileInput"
              >
                <el-select
                  v-model="userInfoForm.area_code"
                  slot="prepend"
                  popper-class="registerAreaCodeList"
                  filterable
                  :filter-method="areaCodeFilter"
                >
                  <el-option
                    v-for="(item, index) in areaCodeList"
                    :key="item.area_code + index + ''"
                    :value="item.area_code"
                    :title="item.country | areacodeTitleFormat(LOCALE)"
                  >
                    <p class="area_code">{{ item.area_code }}</p>
                    <p class="country textOverflow">
                      {{ item.country | areacodeAddressFormat(LOCALE) }}
                    </p>
                  </el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Gender:</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.sex | sexFormat("en") }}</p>
            <el-form-item v-else>
              <el-select
                v-model="userInfoForm.sex"
                placeholder=" "
                size="small"
              >
                <el-option
                  v-for="item in sexList"
                  :key="item.value"
                  :label="item.label_en"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Position in Company:</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.job_title_en | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.job_title_en"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item clearfix">
          <span class="key">Birthday:</span>
          <span class="value">
            <p v-if="isExamine">
              {{ USER_INFO.birthday | secondFormat("LL") }}
            </p>
            <el-form-item v-else>
              <el-date-picker
                style="width: 250px"
                v-model="userInfoForm.birthday"
                type="date"
                size="small"
              ></el-date-picker>
            </el-form-item>
          </span>
        </div>
      </div>
      <p class="contactInfo_tip litterTitle">{{ $t("contactInfoTip") }}</p>
      <div class="contactInfo">
        <div class="item">
          <span class="key">LinkedIn:</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.linkedin | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.linkedin"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item">
          <span class="key">FaceBook：</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.facebook | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.facebook"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item">
          <span class="key">Skype：</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.skype | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.skype"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item">
          <span class="key">Wechat ID:</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.wechat | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.wechat"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
        <div class="item">
          <span class="key">QQ：</span>
          <span class="value">
            <p v-if="isExamine">{{ USER_INFO.qq | textFormat }}</p>
            <el-form-item v-else>
              <el-input
                v-model="userInfoForm.qq"
                size="small"
                class="input"
              ></el-input>
            </el-form-item>
          </span>
        </div>
      </div>
      <div class="footer-btn">
        <el-button
          type="primary"
          size="small"
          style="padding: 9px 25px"
          :loading="saveLoading"
          @click="confirm"
          >{{ $t("save") }}</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { sexList } from "~/baseUtils/enumValue";
import fileUpload from "~/baseComponents/fileUpload";
import pageTitle from "~bac/components/common/pageTitle";
import { areaCodeList } from "~/baseUtils/AddressConfig";
export default {
  components: { fileUpload, pageTitle },
  data() {
    return {
      from_source: "",
      areaCodeList,
      areaCodeListCopy: [].concat(areaCodeList),
      userInfoForm: {},
      isExamine: false,
      sexList,
      saveLoading: false,
      fileUploadOptions: {
        needCropImg: true,
        aspectRatio: 20 / 20,
        accept: ".jpg,.jpeg,.bmp,.png",
      },
      avatarImg:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/default-logo.jpg",
      userInfoRule: {
        name_en: [
          {
            required: true,
            message: " ",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    USER_INFO: {
      deep: true,
      handler(nv) {
        this.initFormData();
      },
    },
  },
  methods: {
    initFormData() {
      this.userInfoForm = {
        area_code: this.USER_INFO.area_code ? this.USER_INFO.area_code : "+86",
        name_en: this.USER_INFO.name_en,
        sex: this.USER_INFO.sex,
        job_title_en: this.USER_INFO.job_title_en,
        birthday: this.USER_INFO.birthday
          ? this.$moment(this.USER_INFO.birthday * 1000).format("YYYY-MM-DD")
          : "",
        linkedin: this.USER_INFO.linkedin,
        facebook: this.USER_INFO.facebook,
        skype: this.USER_INFO.skype,
        wechat: this.USER_INFO.wechat,
        qq: this.USER_INFO.qq,
        avatar: this.USER_INFO.avatar,
        email: this.USER_INFO.email,
        mobile: this.USER_INFO.mobile ? this.USER_INFO.mobile : "",
      };
    },
    uploadimg(result) {
      this.userInfoForm.avatar = result.url;
    },
    async confirm() {
      this.saveLoading = true;
      try {
        let params = Object.assign({}, this.userInfoForm);
        params.user_id = this.USER_INFO.id;
        if (params.birthday) {
          params.birthday = this.$moment(params.birthday).unix();
        }
        let result = await this.$store.dispatch(
          "baseConsole/user_saveUserInfo",
          params
        );
        if (result.success) {
          if (this.userInfoForm.birthday) {
            this.$moment(this.userInfoForm.birthday * 1000).format(
              "YYYY-MM-DD"
            );
          } else {
            this.userInfoForm.birthday = "";
          }
          this.$ssTip();
          if (this.from_source != "") {
            this.$router.push({ path: this.from_source });
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.saveLoading = false;
    },
    areaCodeFilter(value) {
      if (value) {
        this.areaCodeList = this.areaCodeListCopy
          .filter((item) => {
            return item.area_code.indexOf(value) == 1;
          })
          .sort((a, b) => {
            return parseInt(a.area_code) - parseInt(b.area_code);
          });
      } else {
        this.areaCodeList = this.areaCodeListCopy;
      }
    },
  },
  mounted() {
    if (this.$route.query.parameter) {
      this.from_source = this._decode(this.$route.query.parameter).source;
    }

    this.initFormData();
  },
};
</script>

<style lang="less" scoped>
.userInfoEn {
  div {
    color: #808080;
    p {
      color: #808080;
    }
  }
  .explain {
    padding: 10px 12px;
    line-height: 18px;
    margin-bottom: 10px;
    border-radius: 0px;
    color: #555;
    background-color: #f9f9f9;
    border-top: 2px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  .key {
    width: 160px;
    float: left;
    color: #808080;
    text-align: right;
    padding-right: 10px;
    line-height: 35px;
  }
  .avatar {
    width: 100%;
  }
  .imgDiv {
    position: relative;
    width: 100px;
    height: 100px;
    .markImg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      line-height: 100px;
      transition: 0.5s;
      span {
        color: #fff;
        display: none;
      }
    }
    &:hover {
      .markImg {
        background-color: rgba(0, 0, 0, 0.5);
        span {
          display: block;
        }
      }
    }
  }
  /deep/.el-select {
    width: 250px;
  }
  .mobileInput {
    .el-select {
      width: 100px;
    }
  }
  .litterTitle {
    margin: 10px 0 20px;
  }
  .input {
    width: 400px;
  }
  .footer-btn {
    padding: 0 0 20px 160px;
  }
}
</style>