<template>
  <div>
    <el-select
      size="small"
      v-model="saleId"
      filterable
      :disabled="disabled"
      :placeholder="placeTitle"
      style="width:300px"
    >
      <el-option
        v-for="(item, index) in options"
        :key="index"
        :label="item.name_zh || item.name_en"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    placeTitle: {
      type: String,
      default: "请选择",
    },
    saleName: {
      default: "",
    },
    value: {
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sale: null,
      saleId: null,
      options: null,
    };
  },
  mounted() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      let data = await this.$store.dispatch("baseConsole/get_sales_list");
      if (data.success) {
        this.options = data.data;
        this.saleId = this.value;
      }
   
    },
  },
  watch: {
    saleId(nv) {
      if (nv) {
        this.$emit("input", nv);
      }
    },
  },
};
</script>

<style>
</style>