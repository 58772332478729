<template>
  <div class="account_userInfo">
    <div v-if="PJID == 'YMP'">
      <userZhYmp></userZhYmp>
    </div>
    <div v-else class="content tabBlock">
      <div
        v-if="BACCONFIG.basicInfoLocale == 'zh'"
        :class="{ marginT: BACCONFIG.basicInfoLocale != 'all' }"
      >
        <userZh></userZh>
      </div>
      <div
        v-else-if="BACCONFIG.basicInfoLocale == 'en'"
        :class="{ marginT: BACCONFIG.basicInfoLocale != 'all' }"
      >
        <userEn></userEn>
      </div>
      <el-tabs :value="currentLocale" v-else>
        <el-tab-pane
          :label="$t('englishInfo')"
          name="en"
          v-if="currentLocale == 'en'"
        >
          <userEn></userEn>
        </el-tab-pane>
        <el-tab-pane :label="$t('chineseInfo')" name="zh">
          <userZh></userZh>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('englishInfo')"
          name="en"
          v-if="currentLocale == 'zh'"
        >
          <userEn></userEn>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import pageTitle from "~bac/components/common/pageTitle";
import userZh from "~bac/components/userInfo/Zh";
import userZhYmp from "~bac/components/userInfo/ZhYmp";
import userEn from "~bac/components/userInfo/En";

export default {
  components: { pageTitle, userZh, userEn ,userZhYmp},
  data() {
    return {
      currentLocale: "",
    };
  },
  methods: {},
  mounted() {
    if(this.PJID == 'YMP'){
      this.$log({
        type: 2,
        topic: "ymp.workbench",
        id: "ymp.workbench.info",
        content: JSON.stringify({
          id: this.USER_INFO.id || ''
        }),
      });
    }

    this.currentLocale = this.LOCALE;
  },
};
</script>

<style lang="less" scoped>
.account_userInfo {
  div {
    color: #808080;
    p {
      color: #808080;
    }
  }
  .content {
    // padding: 10px 12px 5px;
  }
  .litterTitle {
    margin: 10px 0 20px;
  }
  .explain {
    padding: 10px 12px;
    line-height: 18px;
    margin-bottom: 10px;
    border-radius: 0px;
    color: #555;
    background-color: #f9f9f9;
    border-top: 2px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}
.marginT {
  margin-top: 10px;
}
/deep/.el-form {
  padding: 0 12px;
}
</style>